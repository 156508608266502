import { useStaticQuery, graphql } from "gatsby"

export const useSiteSettings = () => {
  const { sanitySettings } = useStaticQuery(graphql`
    query {
      sanitySettings {
        footerNav {
          _key
          _type
          linkText
          pageReference {
            ... on SanityStandardPage {
              id
              name
              _type
              slug {
                current
              }
            }
          }
        }
        footerNavQuery {
          _key
          _type
          linkText
          pageReference
        }
        mainNav {
          _key
          _type
          linkText
          pageReference {
            ... on SanityStandardPage {
              id
              name
              _type
              slug {
                current
              }
            }
          }
        }
        footerText
        businessPhone
        businessInstagram
        businessFacebook
        businessLinkedin
        businessEmail
        _rawBusinessAddress(resolveReferences: { maxDepth: 10 })
        addressGoogleLink
        mainNav {
          linkText
          pageReference {
            ... on SanityStandardPage {
              name
              _type
              slug {
                current
              }
            }
          }
        }
        siteName
      }
    }
  `)
  return sanitySettings || {}
}
