import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import is from "styled-is"

// SVG
import DownloadIcon from "../../svg/download.svg"

export const NavLink = ({ linkText, pageReference, ...props }) => {
  const { slug, _type } = pageReference
  return (
    <Link
      to={_type === "homePage" ? `/` : `/${slug.current}`}
      activeClassName="active"
      {...props}
    >
      {linkText}
    </Link>
  )
}

export const NavLinkQuery = ({ linkText, pageReference, ...props }) => {
  return (
    <Link
      to={`/${pageReference}`}
      activeClassName="active"
      {...props}
    >
      {linkText}
    </Link>
  )
}


export const StyledTextLink = styled.a`
  color: inherit;
  text-decoration: underline;
  padding: 0;
  /* Color Modifiers */
  ${is("pullColor")`
    color: ${props => props.theme.colors.primary.one};
  `}
`

export const StyledButton = styled.button`
  border-radius: 100px;
  padding: 0.8rem 2rem;
  background: ${props => props.theme.colors.secondary.three};
  display: inline-block;
  vertical-align: center;
  > svg {
    margin-left: 1rem;
  }
`

export const DownloadButton = ({ children, file }) => {
  return (
    <StyledButton href={file} as="a" target="_blank">
      {children}
      <DownloadIcon />
    </StyledButton>
  )
}
