import React from "react"
import styled from "styled-components"

// Import components
import { StandardContainer } from "../common"
import { ProductCard } from "./"

const StyledProductGrid = styled(StandardContainer)`
background-color:white;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 3rem;
  @media ${props => props.theme.breakpoints.medium} {
    padding-bottom: 6rem;
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  @media ${props => props.theme.breakpoints.large} {
    /* padding-top:6rem; */
    /* position: -webkit-sticky; 
    position: sticky;
    top: 0;
    z-index:-1; */
  }
`

const CardWrapper = styled.div`
  width: 100%;
  @media ${props => props.theme.breakpoints.medium} {
    width: calc(50% - 40px);
    margin: 0 20px 47px;
  }
  @media ${props => props.theme.breakpoints.large} {
    width: calc((100% / 3) - 40px);
  }
`

export const ProductGrid = ({ products }) => {
  return (
    <StyledProductGrid padH>
      {products.map(product => (
        <CardWrapper>
          <ProductCard {...product} />
        </CardWrapper>
      ))}
    </StyledProductGrid>
  )
}
