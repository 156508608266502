import React, { useEffect } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Div100vh from 'react-div-100vh'

// Hooks State
import { useAppContext } from "../../state"
// Import components
import { StandardContainer, StyledHeading } from "../common"

// Styles
const StyledBannerRegular = styled(StandardContainer)`
  background-color: ${props => props.theme.colors.primary.one};
  height:100%;
  .gatsby-image-wrapper {
    > div {
      display: none;
    }
    height: 65%;
  }
  @media ${props => props.theme.breakpoints.large} {
     display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    align-items: flex-end;
    .gatsby-image-wrapper {
      width: 60%;
      height:100%;
    }    
  }
`

const TextWrapper = styled(StandardContainer)`
  padding: 0 1.4rem 1.4rem;
  height: 35%;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  color: white;
  align-content: flex-end;  
  @media ${props => props.theme.breakpoints.medium} {    
    padding: 3rem 2rem;
    h3 {
      margin-bottom: 0;
    }
    
  }
  @media ${props => props.theme.breakpoints.large} {
    display: flex;
    flex-wrap: wrap;    
    width: 40%;
    height: 50%;
    * {
      width: 100%;
    }
  }
`

const Button = styled(AnchorLink)`
  display: none;
  color: white;
  font-weight: 600;
  margin-top: 1rem;
  @media ${props => props.theme.breakpoints.medium} {
    display: block;
    text-align: left;
  }
`

export const BannerProduct = ({ heading, image, description }) => {
  const { setReverseHeader } = useAppContext()
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  })

  useEffect(() => {
    setReverseHeader(inView)
    return () => {
      setReverseHeader(false)
    }
  }, [inView, setReverseHeader])

  return (
    <Div100vh style={{ height: '100rvh' }}>
      <StyledBannerRegular ref={ref}>
        {image && <Img fluid={image.asset.fluid} />}
        <TextWrapper>
          {heading && (
            <StyledHeading as="h3" size="h3" reversed>
              {heading}
            </StyledHeading>
          )}
          {description && <p>{description}</p>}
          <Button href={`#tabbedContent`}>View Specifications</Button>
        </TextWrapper>
      </StyledBannerRegular>
    </Div100vh>
  )
}
