import React from "react"
import styled from "styled-components"

// Import components
import { StandardContainer, StyledHeading } from "../common"

// Styles
const StyledTextLayout = styled(StandardContainer)`
  background-color: ${props => props.bgColor || "#fff"};
  color: ${props =>
    props.bgColor === "#256EEF" || props.bgColor === "#031E44"
      ? "#ffffff"
      : "#031E44"};
  padding-top: 7rem;
  padding-bottom: 7rem;
  text-align: center;
  h2 {
    max-width: 1140px;
    margin:0 auto;
  }

  @media ${props => props.theme.breakpoints.medium} {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  @media ${props => props.theme.breakpoints.large} {
    height:100vh;
    max-height:1500px;
    /* position: -webkit-sticky; 
    position: sticky;
    top: 0;
    z-index:-1; */
    display:flex;
    align-items:center;
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
`

export const LargeText = ({ heading, bgColor }) => {
  return (
    <StyledTextLayout padH bgColor={bgColor}>
      {heading && (
        <StyledHeading as="h2" size="h2">
          {heading}
        </StyledHeading>
      )}      
    </StyledTextLayout>
  )
}
