import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { Link } from "gatsby"

// Import components
import { StyledHeading } from "../common"

const TextWrapper = styled("div")`
  min-height: 203px;
  padding: 30px 28px 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position:relative;
  padding-bottom:57%;
  h4 {
    position:absolute;
    top:0;
    left:0;
    right:0;
    padding:28px;
  }
  h5 {
    position:absolute;
    bottom:67px;
    left:0;
    right:0;
    padding:28px 28px 0;
    margin:0;
  }
  span {
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    padding:0 28px 28px;
  }
  @media ${props => props.theme.breakpoints.large} {
    /* height: 300px; */
  }
`

const StyledProductCard = styled(Link)`
  display: block;
  position: relative;
  background-color: ${props => props.theme.colors.primary.three};
  width: 100%;
  margin: 0 0 20px;
  .gatsby-image-wrapper {
    z-index: 1;
    width: 100%;
    transition:all 0.2s ease;
    > div {
      padding-bottom: 73% !important;
    }
  }
  span {
    font-weight: bold;
  }
  h4 {
    transition:all 0.2s ease;
    font-size:28px;
  }
  h5 {
    opacity:0;
    transition:all 0.2s ease;
  }
  @media ${props => props.theme.breakpoints.medium} {
    &:hover {
      .gatsby-image-wrapper {
        opacity: 0;        
      }
      h4 {
       opacity:0;
      }
      h5 {
        opacity:1;
      }
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    h4 {
      font-size:30px;
    }

  }
`

const HoverHeading = styled(StyledHeading)`
  position: absolute;
  top: 30px;
  left: 28px;
  right: 28px;
`
const HoverDescription = styled(StyledHeading)``

export const ProductCard = ({
  heading,
  featuredImage,
  slug,
  shortDescription,
}) => {
  return (
    <>
      {heading && (
        <StyledProductCard to={`/products/${slug.current}`}>
          <HoverHeading as="h5" size="h5">
            {heading}
          </HoverHeading>
          {featuredImage && <Img fluid={featuredImage.asset.fluid} />}
          <TextWrapper>
            <StyledHeading as="h4" size="h5">
              {heading}
            </StyledHeading>
            <HoverDescription as="h5" size="h6">
              {shortDescription}
            </HoverDescription>
            <span>View Product</span>
          </TextWrapper>
        </StyledProductCard>
      )}
    </>
  )
}
