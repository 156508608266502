import React, { useState, useCallback, useEffect } from "react"
import styled from "styled-components"
import { ProductCard } from "../pageBlocks/"
import { useEmblaCarousel } from "embla-carousel/react"
// import { setupWheelGestures } from "embla-carousel-wheel-gestures"

// Components
import { StandardContainer } from "../common"

// Styles
const StyledCardCarousel = styled(StandardContainer)`
  position: relative;
  > nav {
    text-align:center;
    margin: 2rem 0;

     button {
      width: 8px;
      height: 8px;
      padding: 0;
      border-radius: 15px;
      box-sizing: border-box;
      background: #E1E5EC;
      margin: 0 6px;
      cursor: pointer;
      transition: opacity 0.2s;
      background: "#E1E5EC";
      &.is-selected {
        background:${props => props.theme.colors.primary.two};
      }
      @media ${props => props.theme.breakpoints.medium} {
        display:none;
        margin: 0 0 0 12px;
        width: 12px;
        height: 12px;
      }    
    }
  }
  @media screen and (max-width: 480px) {
    > nav {
      padding-left: var(--pH);
      padding-right: var(--pH);
    }
  }
`

const StyledCardCarouselCanvas = styled.div`
  display: flex;


  
`
const CardWrapper = styled.div`
  width: 100%;
  flex: 0 0 auto;
  padding-right: 1rem;
  box-sizing: content-box;  
  @media ${props => props.theme.breakpoints.medium} {
    padding-right: 40px;
    width: 80%;
    /* padding-right: 40px; */
  }
  @media ${props => props.theme.breakpoints.large} {
    width: 38%;
  }
  @media ${props => props.theme.breakpoints.xl} {
    width: 30%;
  }
  @media screen and (min-width: 1900px) {
    width: 23%;
  }
`

const DotButton = ({ selected, onClick }) => (
  <button
    className={`embla__dot ${selected ? "is-selected" : ""}`}
    type="button"
    onClick={onClick}
  />
);


export const NavigationCarousel = ({ products }) => {
  const [EmblaCarouselReact, embla] = useEmblaCarousel({
    loop: false,
    dragFree: true,
    align: "start",
    containScroll: true,
  })

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [
    embla
  ]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <>
    <StyledCardCarousel>
      <EmblaCarouselReact>
        <StyledCardCarouselCanvas>
          {products.map((product, index) => (
            <CardWrapper className="embla__slide" key={index}>
              <ProductCard {...product} />
              {/* <h1>slide</h1> */}
            </CardWrapper>
          ))}
        </StyledCardCarouselCanvas>
      </EmblaCarouselReact>
      <nav>
        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>   
      </nav>
    </StyledCardCarousel>
    </>
  )
}

