import React from "react"
import styled from "styled-components"
// Import common components
import { CarouselSingle, StandardContainer } from "../common"

// Styles

const StyledProductCarousel = styled(StandardContainer)`
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: ${props => props.bgColor || "#fff"};
  cursor: grab;  
  .is-draggable {
    overflow: visible !important;
  }
  @media ${props => props.theme.breakpoints.large} {

    min-height: 100vh;
  }
`

export const ImageCarousel = ({ images }) => {
  return (
    images && (
      <StyledProductCarousel className="image-carousel">
        <CarouselSingle images={images} />
      </StyledProductCarousel>
    )
  )
}
