export const siteTheme = {
  breakpoints: {
    ns: "screen and (min-width: 480px)",
    medium: "screen and (min-width: 480px)",
    large: "screen and (min-width: 1024px)",
    xl: "screen and (min-width: 1500px)",
  },
  colors: {
    primary: {
      one: "#031E44",
      two: "#256EEF",
      three: "#EFF5FF",
    },
    secondary: {
      one: "#000000",
      two: "#EFF5FF",
      three: "#DEDEDE",
    },
  },
  typography: {
    family: {
      standard: "Spezia",
      bold: "Spezia",
      heading: "Spezia",
    },
    sizes: {
      regular: "calc(16px + (18 - 16) * ((100vw - 320px) / (1800 - 320)))",
      smallReg: "calc(12px + (14 - 12) * ((100vw - 320px) / (1800 - 320)))",
      h1: "calc(45px + (90 - 45) * ((100vw - 320px) / (1800 - 320)))",
      h2: "calc(30px + (60 - 30) * ((100vw - 320px) / (1800 - 320)))",
      h3: "calc(30px + (50 - 30) * ((100vw - 320px) / (1800 - 320)))",
      h4: "calc(22px + (37 - 22) * ((100vw - 320px) / (1800 - 320)))",
      h5: "calc(20px + (29 - 20) * ((100vw - 320px) / (1800 - 320)))",
      h6: "calc(19px + (21 - 19) * ((100vw - 320px) / (1800 - 320)))",
    },
    lineHeights: {
      standard: "1.6",
      heading: "1.3",
    },
    letterSpacing: {
      standard: "0",
      nav: "0.81px",
    },
    measures: {
      large: "55em",
      medium: "45em",
      standard: "30em",
      narrowHeading: "16em",
      heading: "22em",
    },
    hardBreak: "2rem",
  },
  padding: {
    hMobile: "1.4rem",
    hDesk: "4vw",
    vMobile: "70px",
    vDesk: "6rem",
  },
  containerWidths: {
    narrow: "1140px",
    standard: "1270px",
  },
}
