import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

// Import components
import { StandardContainer, StyledHeading } from "../common"

// Styles
const StyledImageCard = styled.div`
  width: calc((100% / 2) - 20px);
  margin: 0 0 2rem;
  /* flex: 0 0 auto; */
  box-sizing: content-box;
  border:solid 1px #C3D0E1;
  .gatsby-image-wrapper > div {
    width: 100%;
    display: none;
  }
  picture img {
    position: relative !important;
  }
  @media ${props => props.theme.breakpoints.medium} {
    width: calc((100% / 3) - 20px);
  }
`

// Styles
const StyledImageGrid = styled(StandardContainer)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  @media ${props => props.theme.breakpoints.medium} {
  }
  @media ${props => props.theme.breakpoints.large} {
  }
`

export const ImageGrid = ({ heading, images, bgColor }) => {
  return (
    <StyledImageGrid bgColor={bgColor}>
      {images.length ? (
        <>
          {images.map((item, index) => (
            <StyledImageCard>
              <Img fluid={item.asset.fluid} />
              <p>{item.caption}</p>
            </StyledImageCard>
          ))}
        </>
      ) : (
        <StyledHeading size="h2" centerText measureWide>
          Add some images
        </StyledHeading>
      )}
    </StyledImageGrid>
  )
}
