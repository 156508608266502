import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import { globalHistory } from "@reach/router"
// Import components
import { BlockZone } from "../pageBlocks"

const TabNav = ({ content, setPageIndex, pageIndex }) => {
  // Grab the search param from reach router
  const search = globalHistory.location.search
  // Find the target and convert to a number
  const target = parseInt(search.split("?target=")[1])
  // Listen on mount if we have a target set the index
  useEffect(() => {
    if (target) {
      setPageIndex(target)
    }
  }, [setPageIndex, target])

  return (
    <nav>
      {content.map((item, index) => (
        <StyledButton
          onClick={() => setPageIndex(index)}
          className={index === pageIndex && `active`}
          data-id={index}
        >
          {item.heading}
        </StyledButton>
      ))}
    </nav>
  )
}

const StyledTabs = styled.div`
  width: 50%;
`

const StyledTabNav = styled(TabNav)``

const TabContent = props => {
  const block = { ...props }
  return (
    <>
      <BlockZone blocks={[block]} />
    </>
  )
}

const StyledButton = styled.button`
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.015em;
  margin-bottom: 25px;
  display: block;
  font-weight: normal;
  opacity: 0.35;
  &.active {
    opacity: 1;
  }
`

export const TabbedDesktop = ({ content }) => {
  const [pageIndex, setPageIndex] = useState(0)

  return (
    <>
      {/* Nav */}
      <StyledTabNav
        content={content}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
      />
      {/* Content */}
      <StyledTabs>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key={pageIndex}
          >
            <TabContent {...content[pageIndex]} />
          </motion.div>
        </AnimatePresence>
      </StyledTabs>
    </>
  )
}
