import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import styled from "styled-components"

// Context / Hooks
import { useAppContext } from "../../state"
import { useScrollFreeze } from "../../hooks"

// Common Compoents
import { NavLink } from "../common"
// Styles
const StyledMobileNav = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  background: ${props => props.theme.colors.primary.one};
  color: ${props => props.theme.colors.primary.three};
  display: flex;
  align-items: center;
  justify-content: center;
  > nav {
    padding: 0 ${props => props.theme.padding.hMobile};
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    text-align:center;
  }
  @media ${props => props.theme.breakpoints.medium} {
    padding: 0 ${props => props.theme.padding.hDesk};
  }
`
const StyledMobileNavLink = styled(NavLink)`
  font-size: 30px;
  margin:12px 0;
  &.active {
    color: ${props => props.theme.colors.primary.two};
  }
`

// Animation Variants

const variants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,    
  },
}

const navVariants = {
  open: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2,
    },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const linkVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      damping: 300,
    },
  },
  closed: {
    y: 20,
    opacity: 0,
    transition: {
      type: "spring",
      damping: 300,
    },
  },
}

const NavInner = ({ navItems }) => {
  useScrollFreeze()
  const { closeNav } = useAppContext()

  return (
    <StyledMobileNav
      variants={variants}
      initial="closed"
      animate="open"
      exit="closed"
    >
      <motion.nav variants={navVariants}>
        {navItems.map(item => (
          <motion.div variants={linkVariants}>
            <StyledMobileNavLink {...item} onClick={closeNav} />
          </motion.div>
        ))}
      </motion.nav>
    </StyledMobileNav>
  )
}

export const MobileNav = ({ items }) => {
  const navItems = items || []
  const { navOpen } = useAppContext()
  return (
    <AnimatePresence>
      {navOpen && <NavInner navItems={navItems} />}
    </AnimatePresence>
  )
}
