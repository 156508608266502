import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import is from "styled-is"

// Import components
import {
  StandardGridContainer,
  StyledHeading,
  SanityPortableText,
} from "../common"

// Styles
const StyledImageText = styled(StandardGridContainer)`
  background-color: ${props => props.bgColor || "#fff"};
  color: ${props =>
    props.bgColor === "#256EEF" || props.bgColor === "#031E44"
      ? "#ffffff"
      : "#031E44"};
  grid-template-rows: auto auto auto;
  h3 {
    padding:1.4rem;
    grid-row: 1 / span 1;
    grid-column: 1 / span 9;
  }
  .sanity-portable-text {
    padding:1.4rem;
    grid-row: 2 / span 1;
    grid-column: 1 / span 12;
  }
  .gatsby-image-wrapper {
    grid-row: 3 / span 1;
    grid-column: 1 / span 15;
    > div {
      padding-bottom: 100% !important;
    }
  }
  @media ${props => props.theme.breakpoints.medium} {    
    grid-template-rows: 1fr 10rem 1fr;
    grid-template-columns:repeat(12,1fr);    
    h3 {
      grid-row: 1 / span 1;
      grid-column: 1 / span 3;
      padding:2rem 0 2rem 2rem;
    }
    .sanity-portable-text {
      grid-row: 3 / span 1;
      grid-column: 1 / span 6;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      padding:3rem 2rem;
      /* * {
        max-width: 500px;
        margin:0 auto;
      } */
    }
    .gatsby-image-wrapper {
      grid-row: 1 / span 3;
      grid-column: 7 / span 6;
      > div {
        padding-bottom: 125% !important;
      }
    }
    ${is("reverseLayout")`
    h3 {
      grid-row: 1 / span 1;
      grid-column: 7 / span 3;    
      padding:2rem 0 2rem 2rem;
    }
    .sanity-portable-text {
      grid-row: 3 / span 1;
      grid-column: 7 / span 6;
      justify-content: flex-end;
      padding:3rem 2rem;
    }
    .gatsby-image-wrapper {
      grid-row: 1 / span 3;
      grid-column: 1 / span 6;      
    }
    `}
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-template-rows: 1fr auto 1fr;
    height: 100vh;
    /* position: -webkit-sticky; 
    position: sticky;
    top: 0;
    z-index:-1; */
  }
`

export const ImageText = ({
  heading,
  bgColor,
  _rawText,
  image,
  reverseLayout,
}) => {
  return (
    <StyledImageText reverseLayout={reverseLayout} bgColor={bgColor}>
      {heading && (
        <StyledHeading as="h3" size="h3" noMargin>
          {heading}
        </StyledHeading>
      )}
      {_rawText && <SanityPortableText text={_rawText} />}
      {image && <Img fluid={image.asset.fluid} />}
    </StyledImageText>
  )
}
