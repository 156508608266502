import React from "react"
import styled from "styled-components"

// Hooks,Querys & State
import { useSiteSettings } from "../../GraphQl/queryHooks"

// Common Components
import {
  StandardContainer,
  NavLinkQuery,
  StyledHeading,
  SanityPortableText,
} from "../common"

// Styles
const StyledSiteFooter = styled.div`
  footer {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-between;
    padding-bottom: 1rem;
    width: 100%;
    background: ${props => props.theme.colors.primary.three};
    h2 {
      margin-top: 6rem;
      margin-bottom: 6rem;
    }
    
    nav {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      > a {
        display: flex;
        width: 50%;
        margin: 0.3rem 0;
        color: ${props => props.theme.colors.primary.one};
        &.active {
          /* font-weight: bold; */
        }
      }
    }
    @media ${props => props.theme.breakpoints.medium} {
      h2 {
        margin-top: 8rem;
        margin-bottom: 8rem;
      }
      h5 {
        margin-top: 0;
      }
    }
  }
`
const FooterSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  .sanity-portable-text {
    margin-bottom: 2rem;
  }
  @media ${props => props.theme.breakpoints.medium} {
    width: 47%;
  }
  @media ${props => props.theme.breakpoints.large} {
    width: 40%;
  }
`

const FooterStrapline = styled(StyledHeading)`
  font-weight: bold;
  width: 100%;
  text-align: center;
  span {
    max-width: 16em;
    margin: 0 auto;
  }
`

const FooterHeading = styled(StyledHeading)`
  font-weight: bold;
  width: 100%;
  margin-bottom: 1.5rem;
`

const BottomBar = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;  
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top:2rem;
  font-size: 12px;
  border-top: solid 2px ${props => props.theme.colors.primary.one};
  ul {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  li {
    list-style-type: none;
    margin: 0 5px;
  }
  a {
    font-size: 12px;
    text-decoration: underline;
  }
  @media ${props => props.theme.breakpoints.medium} {
    justify-content: space-between;
    span {
      display: flex;
    }
    ul {
      margin-top: 0;
      width: auto;
    }
  }
`

export const Footer = () => {
  const {
    siteName,
    footerText,
    businessPhone,
    businessInstagram,
    businessFacebook,
    businessEmail,
    businessLinkedin,
    _rawBusinessAddress,
    footerNavQuery,
  } = useSiteSettings()
  return (
    <>
      <StyledSiteFooter>
        <StandardContainer as="footer" padH>
          {footerText && (
            <FooterStrapline as="h2" size="h3">
              <span>{footerText}</span>
            </FooterStrapline>
          )}
          <FooterSection>
            {siteName && (
              <FooterHeading as="h5" size="h6" noMargin>
                {siteName}
              </FooterHeading>
            )}
            {_rawBusinessAddress && (
              <SanityPortableText text={_rawBusinessAddress} />
            )}
            <div>
              {businessPhone && <p>{businessPhone}</p>}
              {businessEmail && <p>{businessEmail}</p>}
            </div>
          </FooterSection>
          <FooterSection>
            <FooterHeading as="h5" size="h6" noMargin>
              Quick Links
            </FooterHeading>
            <nav>
              {footerNavQuery.map(item => (
                <NavLinkQuery {...item} />
              ))}
            </nav>
          </FooterSection>
          <BottomBar>
            <span>Copyright © 2020 {siteName}</span>
            <ul>
              {businessLinkedin && (
                <li>
                  <a href="{businessLinkedin}">LinkedIn</a>
                </li>
              )}
              {businessInstagram && (
                <li>
                  <a href="{businessInstagram}">Instagram</a>
                </li>
              )}
              {businessFacebook && (
                <li>
                  <a href="{businessFacebook}">Facebook</a>
                </li>
              )}
            </ul>
          </BottomBar>
        </StandardContainer>
      </StyledSiteFooter>
    </>
  )
}
