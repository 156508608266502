import React from "react"
import styled from "styled-components"

// Hooks,Querys & State
import { useWindowSize } from "../../hooks"

// Import components
import { StandardContainer } from "../common"
import { TabbedDesktop, TabbedMobile } from "../pageBlocks"

// Styles
const TabbedContentWrapper = styled(StandardContainer)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;  
  padding:3rem 1.3rem 6rem;
  nav {
    width: 50%;
    button {
      font-family: ${props => props.theme.typography.family.standard};
      color: ${props => props.theme.colors.primary.one};
    }
  }
  @media ${props => props.theme.breakpoints.medium} {
    padding-top: 5rem;
    padding-bottom: 8rem;
  }
  @media ${props => props.theme.breakpoints.large} {
    padding:3rem 2rem 6rem;
    min-height:100vh;
  }
`

export const TabbedContent = ({ content }) => {
  const windowSize = useWindowSize() 

  // if (urlParams != null) {
  //   document.getElementById(urlParams).click()
  // }

    return (
      <TabbedContentWrapper id="tabbedContent" padH>
        {windowSize.width >= 1024 && (
          <>
            <TabbedDesktop content={content} />
          </>
        )}
        {windowSize.width < 1024 && (
          <>
            <TabbedMobile content={content} />
          </>
        )}
      </TabbedContentWrapper>
    )
}
