import React from "react"
import styled from "styled-components"
// Import common components
import { NavigationCarousel, StyledHeading, StandardContainer } from "../common"

// Styles

const StyledProductCarousel = styled(StandardContainer)`
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: ${props => props.bgColor || "#fff"};
  cursor: grab;
  padding-bottom: 4rem;
  header {
    text-align: center;
    padding: 4rem 0;
  }
  .is-draggable {
    overflow:visible !important;
  }
  @media ${props => props.theme.breakpoints.large} {
    header {
    padding: 8rem 0;
  }
    min-height:100vh;
  }
`


export const ProductCarousel = ({ heading, products }) => {
  return (
    products && (
      <StyledProductCarousel padH>
        {heading && (
          <header>
            <StyledHeading as="h2" size="h3" noMargin>
              {heading}
            </StyledHeading>
          </header>
        )}
        <NavigationCarousel products={products} />                  
      </StyledProductCarousel>
    )
  )
}
