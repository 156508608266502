import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import { globalHistory } from "@reach/router"

// Import components
import { BlockZone } from "../pageBlocks"
import { StyledHeading } from "../common"

// SVG
import PlusIcon from "../../svg/plus.svg"
import MinusIcon from "../../svg/minus.svg"

// Motion Variants
const variants = {
  open: { opacity: 1, height: "auto" },
  collapsed: { opacity: 0, height: 0 },
}

const TabsWrapper = styled.div`
  display: block;
  width: 100%;
  header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0;
    cursor: pointer;
  }
  section {
    overflow: hidden;
    margin-bottom:2rem;
  }
`

const TabContent = props => {
  const block = { ...props }
  return (
    <>
      <BlockZone blocks={[block]} />
    </>
  )
}

const TabbedItem = ({ index, item, expanded, setExpanded }) => {
  const isOpen = item === expanded 

  // Grab the search param from reach router
  const search = globalHistory.location.search
  // Find the target and convert to a number
  const target = parseInt(search.split("?target=")[1])
  // Listen on mount if we have a target set the index
  useEffect(() => {
    if (target === index ) {
      setExpanded(item)
    } else {
      if (index === 0) {
    setExpanded(item)
      }
    }
  }, [setExpanded, item, target, index])

  return (
    <>
      <TabsWrapper>
        <motion.header
          initial={false}
          onClick={() => setExpanded(isOpen ? false : item)}
        >
          <StyledHeading as="h4" size="h4" noMargin>
            {item.heading}
          </StyledHeading>
          {!isOpen && (<PlusIcon />)}
          {isOpen && (<MinusIcon />)}
        </motion.header>
        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.section
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={variants}
              transition={{ duration: 0.3, ease: [0.4, 0.7, 0.23, 0.98] }}
            >
              <TabContent {...item} />
            </motion.section>
          )}
        </AnimatePresence>
      </TabsWrapper>
    </>
  )
}

export const TabbedMobile = ({ content }) => {
  const [expanded, setExpanded] = useState(false)

 
  return (
    <>
      {content.map((item, index) => (
        <TabbedItem
          index={index}
          item={item}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      ))}
    </>
  )
}
