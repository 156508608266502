import styled from "styled-components"
import { motion } from "framer-motion"
import is, { match, isNot } from "styled-is"

export const StyledHeading = styled(motion.h2)`
  font-family: ${props => props.theme.typography.family.heading};
  line-height: ${props => props.theme.typography.lineHeights.heading};
  ${isNot("noMargin")`
    margin-bottom: 0.8em;
  `}
  /* Color Modifiers */
  ${is("pullColor")`
    color: ${props => props.theme.colors.primary.one};
  `}
  ${is("reversed")`
    color: #ffffff;
  `}
  /* Aligment */
  ${is("center")`
    text-align: center;
    margin:0 auto;
  `}
  /* Size Modifiers */
  ${match("size", "h1")`
    font-size: ${props => props.theme.typography.sizes.h1};
    line-height: ${props => props.theme.typography.lineHeights.heading};
  `}
  ${match("size", "h2")`
    font-size: ${props => props.theme.typography.sizes.h2};
    line-height: ${props => props.theme.typography.lineHeights.heading};
  `}
  ${match("size", "h3")`
    font-size: ${props => props.theme.typography.sizes.h3};
    line-height: ${props => props.theme.typography.lineHeights.heading};
  `}
  ${match("size", "h4")`
    font-size: ${props => props.theme.typography.sizes.h4};
  `}
  ${match("size", "h5")`
    font-size: ${props => props.theme.typography.sizes.h5};
  `}
  ${match("size", "h6")`
    font-size: ${props => props.theme.typography.sizes.h6};
  `}
  /* Width Modifiers */
  ${is("measure")`
    max-width: ${props => props.theme.typography.measures.heading};
  `}
  ${is("measureNarrow")`
    max-width: ${props => props.theme.typography.measures.narrowHeading};
  `}
  ${is("measureWide")`
    max-width: ${props => props.theme.typography.measures.standard};
  `}
   /* Alignment Modifiers */
   ${is("centerText")`
    text-align: center;
  `}
   ${is("measure", "centerText")`
      margin-left: auto;
      margin-right: auto;
  `}
   ${is("measureWide", "centerText")`
      margin-left: auto;
      margin-right: auto;
  `}
`

export const StyledTextColor = styled.span`
  ${match("textColor", "primary")`
    color: ${props => props.theme.colors.primary.one};
  `}
  ${match("textColor", "secondary")`
    color: ${props => props.theme.colors.primary.two};
  `}
  ${match("textColor", "reversed")`
    color: #fff;
  `}
`
