import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

// Import common components
import { StandardCarousel, StyledHeading, StandardContainer } from "../common"

// SVG
// import Cursor from "../../svg/cursor.svg"

// Styles
const StyledImageCard = styled.div`
  width: 50vw;
  flex: 0 0 auto;
  padding-right: 1rem;
  box-sizing: content-box;
  > h4 {
    margin-top: 0.8rem;
    line-height: 1.2;
  }
  @media ${props => props.theme.breakpoints.medium} {
    width: 400px;
    padding-right: 3rem;
  }
`
const StyledImageSlider = styled(StandardContainer)`
/* cursor: none; */
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: ${props => props.bgColor || "#fff"};
  cursor: grab;
  cursor: url('../../../cursor.svg'), auto;
  padding-bottom: 4rem;
  header {
    text-align: center;
    padding: 4rem 0;
  }
  @media ${props => props.theme.breakpoints.medium} {
    padding-top:6rem;
    padding-bottom:6rem;
  }
  @media ${props => props.theme.breakpoints.xl} {
    padding-top:9rem;
    padding-bottom:9rem;
  }
`

export const ImageSlider = ({ images, haeding, bgColor }) => {
  return (
    images && (
      <StyledImageSlider padH bgColor={bgColor}>
        {haeding && (
          <header>
            <StyledHeading as="h2" size="h2" noMargin>
              {haeding}
            </StyledHeading>
          </header>
        )}
        <StandardCarousel>
          {images.map(
            ({ asset, caption }) =>
              asset && (
                <StyledImageCard caption={caption}>
                  <Img fluid={asset.fluid} />
                  <StyledHeading as="h4" size="h6">
                    {caption}
                  </StyledHeading>
                </StyledImageCard>
              )
          )}
        </StandardCarousel>
      </StyledImageSlider>
    )
  )
}
