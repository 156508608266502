import React from "react"
import styled from "styled-components"
// import is, { match } from "styled-is"

// Import Components
import BlockContent from "@sanity/block-content-to-react"
import { StyledHeading, StyledTextColor, DownloadButton } from "./"

// Styles
const StyledPortableText = styled.div`
  font-size: ${props => props.theme.typography.sizes.regular};
  line-height: ${props => props.theme.typography.lineHeights.standard};

  p {
    margin-bottom: 0.3em;
  }
  a {
    font-weight: 600;
  }
`

const StyledSmallBody = styled.div`
  font-size: ${props => props.theme.typography.sizes.smallReg};
`

const DownloadButtonRenderer = props => {
  const { linkText, downloadFile } = props.node || {}

  return (
    downloadFile &&
    linkText && (
      <DownloadButton file={downloadFile.asset.url}>{linkText}</DownloadButton>
    )
  )
}

const BlockRenderer = props => {
  const { style = "normal" } = props.node

  if (/^h\d/.test(style)) {
    return (
      <StyledHeading as={style} size={style}>
        {props.children}
      </StyledHeading>
    )
  }

  if (style === "blockquote") {
    return <blockquote>- {props.children}</blockquote>
  }
  if (style === "small-body") {
    return <StyledSmallBody>{props.children}</StyledSmallBody>
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}

const color = props => {
  return (
    <StyledTextColor className="test" textColor={props.mark.color}>
      {props.children}
    </StyledTextColor>
  )
}

export const SanityPortableText = ({ text }) => {
  return (
    <StyledPortableText className="sanity-portable-text">
      <BlockContent
        blocks={text}
        serializers={{
          types: {
            block: BlockRenderer,
            downloadButton: DownloadButtonRenderer,
          },
          marks: { color },
        }}
      />
    </StyledPortableText>
  )
}
