import React, { useEffect } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import Div100vh from 'react-div-100vh'

// Hooks State
import { useAppContext } from "../../state"
// Import components
import { StandardContainer, StyledHeading } from "../common"

// Styles
const StyledBannerRegular = styled(StandardContainer)`
  background-color: ${props => props.bgColor || "#fff"};
  height:100%;
  .gatsby-image-wrapper {
    > div {
      display: none;
    }
    height:65%;
  }
  @media ${props => props.theme.breakpoints.medium} {
    
  }
  @media ${props => props.theme.breakpoints.large} {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    align-items: flex-end;
    .gatsby-image-wrapper {
      width: 60%;
      height: 100%;
    }
  }
`

const TextWrapper = styled(StandardContainer)`
  padding: 1rem 1.4rem 1.4rem;
  height: 35%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  background-color: ${props => props.bgColorTwo || "#fff"};
  color: "#031E44";
  /* color: ${props =>
    props.bgColorTwo === "#256EEF" || props.bgColorTwo === "#031E44"
      ? "#ffffff"
      : "#031E44"}; */
  h3 {
    color: ${props =>
      props.bgColorTwo === "#256EEF" || props.bgColorTwo === "#031E44"
        ? "#ffffff"
        : "#031E44"};
  }
  @media ${props => props.theme.breakpoints.medium} {
    padding: 3rem 2rem;
    h3 {
      margin-bottom: 0;      
    }
   
  }
  @media ${props => props.theme.breakpoints.large} {
     width: 40%;
    height: 50%;
    * {
      width: 100%;
    }
  }
`

export const BannerRegular = ({ heading, image, bgColor, bgColorTwo }) => {
  const { setReverseHeader } = useAppContext()
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  })

  useEffect(() => {
    setReverseHeader(inView)
    return () => {
      setReverseHeader(false)
    }
  }, [inView, setReverseHeader])

  return (
    <Div100vh style={{ height: '100rvh' }}>
      <StyledBannerRegular bgColor={bgColor} bgColorTwo={bgColorTwo} ref={ref}>
      {image && <Img fluid={image.asset.fluid} />}
      <TextWrapper bgColor={bgColor} bgColorTwo={bgColorTwo}>
        {heading && (
          <StyledHeading as="h3" size="h3" reversed>
            {heading}
          </StyledHeading>
        )}
      </TextWrapper>
      </StyledBannerRegular>
    </Div100vh>
  )
}
