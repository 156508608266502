import React from "react"
import styled from "styled-components"
import SearchIcon from "../../svg/search.svg"

export const StyledTextInput = styled.input`
         width: 100%;
         padding: 1rem 0;
         background: none;
         border: none;
         border-bottom: 1px solid ${props => props.theme.colors.primary.one};
         background-color: #fff;
         font-size: inherit;
         font-family: ${props => props.theme.typography.family.standard};
         &:focus {
           border-bottom: 1px solid ${props => props.theme.colors.primary.two};
         }
         &::placeholder {
           color: ${props => props.theme.colors.primary.one};
         }
       `

export const StyledTextArea = styled.textarea`
         width: 100%;
         padding: 1rem 0;
         background: none;
         border: none;
         border-bottom: 1px solid ${props => props.theme.colors.primary.one};
         background-color: #fff;
         font-size: inherit;
         font-family: ${props => props.theme.typography.family.standard};
         &:focus {
           border-bottom: 1px solid ${props => props.theme.colors.primary.two};
         }
         &::placeholder {
           color: ${props => props.theme.colors.primary.one};
         }
       `

export const StyledSelectList = styled.select`
         display: block;
         font-size: inherit;
         font-family: ${props => props.theme.typography.family.standard};
         color: #444;
         padding: 1rem 0.5rem;
         width: 100%;
         max-width: 100%;
         box-sizing: border-box;
         margin: 0;
         border: 1px solid #c4d7cf;
         border-radius: 0;
         -moz-appearance: none;
         -webkit-appearance: none;
         appearance: none;
         background-color: #fff;
         background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
         background-repeat: no-repeat, repeat;
         background-position: right 0.7em top 50%, 0 0;
         background-size: 0.65em auto, 100%;
         &:focus {
           border-bottom: 1px solid ${props => props.theme.colors.primary.two};
         }
       `

const StyledSearchBox = styled.div`
  position: relative;
  > svg {
    width: 20px;
    position: absolute;
    right: 0;
    top: 20px;
  }
`

export const SearchBox = ({ ...props }) => {
  return (
    <StyledSearchBox>
      <StyledTextInput {...props} />
      <SearchIcon />
    </StyledSearchBox>
  )
}


export const BasicButton = styled.button`
         background-color: transparent;
         color: ${props => props.theme.colors.primary.one};
         font-weight:600;
         padding: 0;
         text-decoration: none;
         border-radius: 0;
         border:none;
         padding: 1rem 0;
         line-height: 1;
         font-family: ${props => props.theme.typography.family.standard};
         font-size: 16px;
         &:focus {
           color: ${props => props.theme.colors.primary.two};           
         }
         @media ${props => props.theme.breakpoints.large} {
           /* padding:0; */
         }
       `
