import React, { createContext, useContext } from "react"
import { useToggle } from "../hooks"

export const AppContext = createContext({
  navOpen: false,
  reverseHeader: false,
})

export const PageWrapper = ({ children }) => {
  // Nav
  const [navOpen, setNavOpen, toggleMenu] = useToggle(false) // eslint-disable-line

  // Header Reverse
  // eslint-disable-next-line
  const [reverseHeader, setReverseHeader, toggleReverseHeader] = useToggle(
    false
  )



  const closeNav = () => {
    setNavOpen(false)
  }

  return (
    <AppContext.Provider
      value={{
        navOpen,
        toggleMenu,
        reverseHeader,
        setReverseHeader,
        closeNav,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext)
