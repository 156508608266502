import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

// Import components
import { StandardContainer, StyledHeading } from "../common"

// Styles
const StyledTrimCard = styled.div`
  width: calc((100% / 2) - 20px);
  margin: 0 0 2rem;
  /* flex: 0 0 auto; */
  box-sizing: content-box;
  border:solid 1px #C3D0E1;
  position:relative;
  .gatsby-image-wrapper > div {
    width: 100%;
    display: none;
  }
  picture img {
    position: relative !important;
  }
  p {
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    padding:0 21px 21px;
    font-weight:600;
    font-size:14px;
    z-index:1;
  }
  @media ${props => props.theme.breakpoints.medium} {
    width: calc((100% / 3) - 20px);
  }
`

// Styles
const StyledImageGrid = styled(StandardContainer)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  @media ${props => props.theme.breakpoints.medium} {
  }
  @media ${props => props.theme.breakpoints.large} {
  }
`

export const TrimDetailsGrid = ({ details, images }) => {
  return (
    <StyledImageGrid>
      {details.length ? (
        <>
          {details.map((item, index) => (            
              <StyledTrimCard>
              <Img fluid={item.image.asset.fluid} />
              <p>{item.heading}</p>
            </StyledTrimCard>
          ))}  
        </>
      ) : (
        <StyledHeading size="h2" centerText measureWide>
          Add some images
        </StyledHeading>
      )}
    </StyledImageGrid>
  )
}
