import React from "react"
import PropTypes from "prop-types"

import {
  Accordion,
  BannerCladding,
  BannerRegular,
  BannerShort,
  ColorGrid,
  Documents,
  HeroCarousel,
  ImageCarousel,
  ImageGrid,
  ImageSlider,
  ImageText,
  LargeText,
  ProductGrid,
  ProductCarousel,
  SpecTable,
  TabbedContent,
  TextBlock,
  TextLayout,
  TextLayoutContact,
  TrimDetailsGrid,
  Video,
} from "./"

// import { SystemElementsBlock } from "../systemElements"

export const BlockZone = ({ blocks }) => {
  // Map blocks to return correct type
  const zoneBlocks = blocks || []
  const block = zoneBlocks.map(block => {
    switch (block._type) {
      case "accordion":
        return <Accordion key={block._key} {...block} />
      case "bannerCladding":
        return <BannerCladding key={block._key} {...block} />
      case "bannerRegular":
        return <BannerRegular key={block._key} {...block} />
      case "bannerShort":
        return <BannerShort key={block._key} {...block} />
      case "colorGrid":
        return <ColorGrid key={block._key} {...block} />
      case "documents":
        return <Documents key={block._key} {...block} />
      case "heroCarousel":
        return <HeroCarousel key={block._key} {...block} />
      case "imageCarousel":
        return <ImageCarousel key={block._key} {...block} />
      case "imageGrid":
        return <ImageGrid key={block._key} {...block} />
      case "imageSlider":
        return <ImageSlider key={block._key} {...block} />
      case "imageText":
        return <ImageText key={block._key} {...block} />
      case "largeText":
        return <LargeText key={block._key} {...block} />
      case "specTable":
        return <SpecTable key={block._key} {...block} />
      case "productCarousel":
        return <ProductCarousel key={block._key} {...block} />
      case "productGrid":
        return <ProductGrid key={block._key} {...block} />
      case "tabbedContent":
        return <TabbedContent key={block._key} {...block} />
      case "textBlock":
        return <TextBlock key={block._key} {...block} />
      case "textLayout":
        return <TextLayout key={block._key} {...block} />
      case "textLayoutContact":
        return <TextLayoutContact key={block._key} {...block} />
      case "trimDetailsGrid":
        return <TrimDetailsGrid key={block._key} {...block} />
      case "video":
        return <Video key={block._key} {...block} />
      default:
        return null
    }
  })
  return <>{block}</>
}

BlockZone.propTypes = {
  blocks: PropTypes.array.isRequired,
}
