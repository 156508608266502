import React from "react"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"
import ReactPlayer from "react-player"

// Background Video
export const StyledBackgroundVideo = styled(props => (
  <ReactPlayer
    playing
    playsinline
    width="100%"
    height="100%"
    loop={true}
    muted
    {...props}
  />
))`
  height: 100% !important;
  position: absolute !important;
  top: 0;
  left: 0;
  video {
    position: absolute;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
  }
`

export const StyledCoverBackground = styled(BackgroundImage)`
  height: 100%;
  width: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: -1;
`
