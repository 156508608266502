import React from "react"
import styled from "styled-components"

// Import components
import { StandardContainer, StyledHeading, SanityPortableText } from "../common"

// Styles
const StyledTextLayout = styled(StandardContainer)`
  background-color: ${props => props.bgColor || "#fff"};
  color: ${props =>
    props.bgColor === "#256EEF" || props.bgColor === "#031E44"
      ? "#ffffff"
      : "#031E44"};
  padding: 2rem 1.4rem;
  @media ${props => props.theme.breakpoints.medium} {     
    padding:0;
    display: grid;    
    grid-template-columns: 2rem repeat(12, 1fr) 2rem;
    grid-template-rows: 2rem auto 24vh auto 2rem;
  }
  h2 {
    grid-row: 2 / span 1;
    grid-column: 2 / span 6;
    align-self: start;
  }
  .sanity-portable-text {
    grid-row: 4 / span 1;
    grid-column: 8 / span 6;
    align-self: end;
  }
  @media ${props => props.theme.breakpoints.large} {
    height: 100vh;
    /* position: -webkit-sticky; 
    position: sticky;
    top: 0;
    z-index:-1; */
    grid-template-columns: 2rem repeat(12, 1fr) 2rem;
    grid-template-rows: 2rem auto auto 2rem;
    h2 {
      grid-row: 2 / span 1;
      grid-column: 2 / span 6;
      align-self: start;
      font-size:60px;
    }
    .sanity-portable-text {
      grid-row: 3 / span 1;
      grid-column: 8 / span 6;
      align-self: end;
      p {
        font-size:22px;
      }
      a {
        font-size:18px;
      }
    }
  }
  @media (min-width:1200px) {
    .sanity-portable-text {
      grid-column: 9 / span 5;
    }
  }
  @media ${props => props.theme.breakpoints.xl} {
  .sanity-portable-text {
      grid-column: 10 / span 4;
    }
  }
`

export const TextLayout = ({ heading, bgColor, _rawText }) => {
  return (
    <StyledTextLayout bgColor={bgColor}>
      {heading && (
        <StyledHeading as="h2" size="h2">
          {heading}
        </StyledHeading>
      )}
      {_rawText && <SanityPortableText text={_rawText} />}
    </StyledTextLayout>
  )
}
