module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-187698672-1","anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"defer":false,"sampleRate":5,"siteSpeedSampleRate":10},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"543d4ea4f05ed4153b1863aa1fcf4dab"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
