import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// SVG
import DownloadIcon from "../../svg/download.svg"

// Import components
import { StandardContainer, StyledHeading } from "../common"

const StyledLink = styled(Link)`
  display: flex;
  flex-wrap:wrap;  
  align-items: center;
  border-bottom: 1px solid #031e44;
  padding: 1rem 0;
  span {
    width:100%;
    display:flex;
    justify-content:space-between;
  }
  h5 {
    font-weight: 600;
    font-size:18px;
    width:100%;
    margin-bottom:1rem;    
  }
  svg {
    margin-left: 20px;
  }
  @media ${props => props.theme.breakpoints.medium} {
    span {      
      width:auto;
      flex-wrap:nowrap;
    }
    h5 {
    width:auto;
    margin-bottom:0;
  }
  flex-wrap:nowrap;
  justify-content: space-between;
  }
`

function formatBytes(a,b=2){if(0===a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return parseFloat((a/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}

const SizeFormatted = ({value}) => {
  
  const formatted = formatBytes(value.size);
  return (
    <>
    <div>
      {formatted}
    </div>
    </>
  )
}

const StyledDocumentWrapper = styled(StandardContainer)``

export const Documents = ({ showSearchBar, content }) => {

  
  return (
    <StyledDocumentWrapper>
      {content.map((document, index) => (
        <StyledLink to={document.file.asset.url} target="_blank">
          <StyledHeading as="h5" size="h6" noMargin>
            {document.heading}
          </StyledHeading>
          <span>
            <SizeFormatted value={document.file.asset} />
            <DownloadIcon />
          </span>
        </StyledLink>
      ))}
    </StyledDocumentWrapper>
  )
}
