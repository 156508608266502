import React from "react"
import styled from "styled-components"

// Import components
import { StandardContainer, SanityPortableText } from "../common"

// Styles
const StyledTextLayout = styled(StandardContainer)`
  @media ${props => props.theme.breakpoints.medium} {
  }
  .sanity-portable-text {
  }
  @media ${props => props.theme.breakpoints.large} {
  }
`

export const TextBlock = ({ _rawText }) => {
  return (
    <StyledTextLayout>
      {_rawText && <SanityPortableText text={_rawText} />}
    </StyledTextLayout>
  )
}
