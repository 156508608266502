import React, { useState } from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"

// SVG
import PlusIcon from "../../svg/plus.svg"
import MinusIcon from "../../svg/minus.svg"

// Import components
import { StandardContainer, StyledHeading, SanityPortableText } from "../common"

// Styles
const StyledAccordion = styled(StandardContainer)`
  width: 100%;
  @media ${props => props.theme.breakpoints.medium} {
    padding-top:1rem;
  }
  @media ${props => props.theme.breakpoints.large} {
  }
`

const AccordionWrapper = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    align-items: start;
    border-bottom: 2px solid #031e44;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    h4 {
      width:calc(100% - 30px);
      font-size:18px;
      font-weight:600;
    }
    svg {
      margin-top:3px;      
    }
  }
  section {
    overflow: hidden;
    *:last-child {
      margin-bottom:2rem;
    }
  }
`

const AccordionItem = ({ i, expanded, setExpanded }) => {
  const isOpen = i === expanded

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <>
      <AccordionWrapper>
        <motion.header
          initial={false}
          onClick={() => setExpanded(isOpen ? false : i)}
        >
          <StyledHeading as="h4" size="h5" noMargin>
            {i.heading}
          </StyledHeading>
          {!isOpen && (<PlusIcon />)}
          {isOpen && (<MinusIcon />)}
        </motion.header>
        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.section
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.3, ease: [0.4, 0.7, 0.23, 0.98] }}
            >
              {i._rawText && <SanityPortableText text={i._rawText} />}
            </motion.section>
          )}
        </AnimatePresence>
      </AccordionWrapper>
    </>
  )
}

export const Accordion = ({ heading, content }) => {
  // This approach is if you only want max one section open at a time. If you want multiple
  // sections to potentially be open simultaneously, they can all be given their own `useState`.
  const [expanded, setExpanded] = useState(false)

  return (
    <StyledAccordion>
      {content.length ? (
        <>
          {content.map((i, index) => (
            <AccordionItem
              i={i}
              expanded={expanded}
              setExpanded={setExpanded}
            />
          ))}
        </>
      ) : (
        <StyledHeading size="h2" centerText measureWide>
          Add some items
        </StyledHeading>
      )}
    </StyledAccordion>
  )
}
