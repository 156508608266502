import React from "react"
import styled from "styled-components"

// Import components
import { StyledHeading, StandardContainer, SanityPortableText, StyledTextInput, StyledTextArea, BasicButton } from "../common"

import { useSiteSettings } from "../../GraphQl/queryHooks" // eslint-disable-line
import useFormValidation from "./useFormValidation" 


// Styles

const ContactInfo = styled.div`
  font-size: 18px;
  tr {
    display: table;
    padding: 10px 0;
    width: 100%;
    line-height: 1.3;
  }
  tr td:nth-of-type(1) {
    font-weight: bold;
  }
  td {
    vertical-align: top;
    width: 50%;
  }
  a {
    line-height: 1.6;
  }
  .sanity-portable-text {
    margin-top:1rem;
  }
  .social a {
    text-decoration: underline;
    display:inline-block;
    margin-right:10px;
  }
  a {
    display: block;
    &:hover {
      text-decoration: underline;
    }
  }
`

const StyledTextLayout = styled(StandardContainer)`
  background-color: ${props => props.bgColor || "#fff"};
  color: ${props =>
    props.bgColor === "#256EEF" || props.bgColor === "#031E44"
      ? "#ffffff"
      : "#031E44"};
  padding: 2rem 1.4rem;
  @media ${props => props.theme.breakpoints.medium} {
    padding: 0;
    display: grid;
    grid-template-columns: 2rem repeat(12, 1fr) 2rem;
    grid-template-rows: 4rem auto 4rem;
  }
  h3 {
    grid-row: 2 / span 1;
    grid-column: 2 / span 6;
    align-self: start;
  }
  div${ContactInfo} {
    grid-row: 2 / span 1;
    grid-column: 2 / span 5;
    align-self: start;
  }
  
  @media ${props => props.theme.breakpoints.large} {
    grid-template-rows: 6rem auto 6rem;
    div${ContactInfo} {
      grid-row: 2 / span 1;
      grid-column: 2 / span 5;
    }
  }
`

const FormWrapper = styled.div`
  /* max-width: ${props => props.theme.measures.medium}; */
  margin-top:3rem;
  .form_item {
    margin-bottom: 1rem;
  }
  .form_button {
    display:flex;
    justify-content:flex-end;
  }
  h5 {
    padding:10px 0;
  }

  @media ${props => props.theme.breakpoints.medium} {
    margin-top:0;
    grid-row: 2 / span 1;
    grid-column: 8 / span 6;
    form {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .form_item {
      display:flex;
      margin-bottom: 1rem;
      width: 48%;
    }
    .form_item.full {
      width:100%;
    }    
    .form_button {
      display:flex;
      width:100%;      
      margin-bottom: 1rem;
    }  
  }
   @media ${props => props.theme.breakpoints.large} {
     grid-row: 2 / span 1;
      grid-column: 7 / span 7;
      .form_button {        
        width:100%;        
    }
   }
`
const INITIAL_STATE = {}

export const TextLayoutContact = ({ bgColor }) => {
    const { handleSubmit, handleChange, success } = useFormValidation(
    INITIAL_STATE
  )

  const {
    businessPhone,
    businessInstagram,
    businessFacebook,
    businessEmail,
    _rawBusinessAddress,
    addressGoogleLink,
  } = useSiteSettings()
  return (
    <StyledTextLayout bgColor={bgColor} wrappedStandard>
      <ContactInfo>
        <table style={{ width: "100%" }}>
          <tr>
            <td>
              <strong>Visit us</strong>
              <br />
              {_rawBusinessAddress && (
                <SanityPortableText text={_rawBusinessAddress} />
              )}
              <a target="_blank" rel="noreferrer" href={addressGoogleLink}>
                <strong>View map</strong>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href={"mailto:" + businessPhone}
                target="_blank"
                rel="noreferrer"
              >
                P: {businessPhone}
              </a>
              <a
                href={"mailto:" + businessEmail}
                target="_blank"
                rel="noreferrer"
              >
                E: {businessEmail}
              </a>
            </td>
          </tr>
          <tr>
            <td className="social">
              {businessFacebook && (
                <a href={businessFacebook} target="_blank" rel="noreferrer">
                  Facebook
                </a>
              )}
              {businessInstagram && (
                <a href={businessInstagram} target="_blank" rel="noreferrer">
                  Instagram
                </a>
              )}
            </td>
          </tr>
        </table>
      </ContactInfo>
      <FormWrapper>
        <form
          name="enquiry-form"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          {success ? (
            <>
              <StyledHeading as="h5" size="h6"><strong >Thank you for your enquiry.</strong></StyledHeading>
              <p>One of our team members will contact you shortly.</p>
            </>
          ) : (
        <>
          <input type="hidden" name="form-name" value="enquiry-form" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <StyledTextInput name="bot-field" onChange={handleChange} />
            </label>
          </p>
          <div className="form_item full">
            {/* <label>Full Name</label> */}
            <StyledTextInput
              placeholder="Full Name"
              type="text"
              name="fullname"
              required={true}
              onChange={handleChange}
            />
          </div>
          <div className="form_item full">
            {/* <label>Email Address</label> */}
            <StyledTextInput
              type="email"
              name="email"
              placeholder="Email Address"
              required={true}
              onChange={handleChange}
            />
          </div>
          <div className="form_item full">
            {/* <label>Phone Number</label> */}
            <StyledTextInput
              type="text"
              name="phone"
              placeholder="Phone Number"
              required="false"
              onChange={handleChange}
            />
          </div>
          <div className="form_item full">
            {/* <label>Enquiry</label> */}
            <StyledTextArea
              rows="6"
              name="comment"
              placeholder="Enquiry"
              required={true}
              onChange={handleChange}
            />
          </div>
          <div className="form_button">
            <BasicButton type="submit">Submit</BasicButton>
          </div>
          </>
          )}
        </form>
      </FormWrapper>
    </StyledTextLayout>
  )
}
