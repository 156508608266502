import React from "react"
import styled from "styled-components"

// Import components
import { StandardContainer, StyledHeading } from "../common"

// Styles
const StyledBannerRegular = styled(StandardContainer)`
  background-color: ${props => props.bgColor || "#fff"};
  height: 30vh;
  
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  svg .logoSecondary {
    fill: ${props =>
      props.bgColor === "#256EEF"
        ? "#ffffff"
        : "#031E44"};
  }
  @media ${props => props.theme.breakpoints.medium} {
    height:35vh;
  }
  @media ${props => props.theme.breakpoints.large} {
    height:550px;
    max-height: 50vh;
  }
`

const TextWrapper = styled(StandardContainer)`
  h2 {
    max-width: 1000px;
    color: ${props =>
      props.bgColor === "#256EEF" || props.bgColor === "#031E44"
        ? "#ffffff"
        : "#031E44"};
  }
`

// function HeaderColor({ color }) {
//   if (color === '#EFF5FF') {
//     return 'light';
//   }

//   if (color === '#256EEF') {
//     return 'bright';
//   }

//   if (color === '#031E44') {
//     return 'dark';
//   }

//   return null;
// }

export const BannerShort = ({ heading, bgColor }) => {

 

  return (
    <StyledBannerRegular bgColor={bgColor}>
      <TextWrapper padH>
        {heading && (
          <StyledHeading as="h2" size="h3" bgColor={bgColor}>
            {heading}
          </StyledHeading>
        )}
      </TextWrapper>
    </StyledBannerRegular>
  )
}
