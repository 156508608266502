import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { ThemeProvider } from "styled-components"

// Styles and theme
import "../styles/fonts.css"
import { siteTheme } from "../theme"
import { GlobalStyles } from "../styles/globalStyles"

// Import Components
import { Header, Footer } from "../components/nav"

const StyledContentWrapper = styled.main`
  /* position: absolute;
  top: 0;
  width: 100%; */
`

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={siteTheme}>
      <GlobalStyles />
      <Header />
      <StyledContentWrapper>{children}</StyledContentWrapper>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
