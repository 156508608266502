import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { motion } from "framer-motion"

// Hooks
import { useToggle } from "../../hooks"

// Import components
import {
  StandardContainer,
  StyledBackgroundVideo,
  MotionContainer,
  StyledHeading,
} from "../common"

// Styles
const StyledVideo = styled(StandardContainer)`
  background-color: ${props => props.theme.colors.primary.one};
  color: white;
  text-align: center;
  .gatsby-image-wrapper {
    display: block;
    position: absolute !important;
    top: 0;
    left: 0%;
    width: 100%;
    bottom: 0;
    /* z-index: -1; */
  }
  @media ${props => props.theme.breakpoints.medium} {    
  }

  @media ${props => props.theme.breakpoints.large} {
    /* position: -webkit-sticky; 
    position: sticky;
    top: 0;
    z-index:-1; */
  }
`

const StyledVideoBlock = styled(motion.div)`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  > button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: +1;
    color:white;
    background-color: rgba(3, 30, 68, 0.4);
    h2 {
      margin-bottom: 0.4rem;
      color:white;
    }
    h5 {
      font-weight:600;
    }
    div:hover h5 {
      text-decoration: underline;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    padding-bottom:100vh;
  }
`

const VideoBlockPlayer = ({
  video,
  subtitle,
  heading,
  isActive,
  setActive,
  ...props
}) => {
  return (
    <StyledVideoBlock {...props}>
      {!isActive && (
        <button onClick={() => setActive(true)}>
          <div>
            {heading && (
              <StyledHeading as="h2" size="h2">
                {heading}
              </StyledHeading>
            )}
            {subtitle && (
              <StyledHeading as="h5" size="h5">
                {subtitle}
              </StyledHeading>
            )}
          </div>
        </button>
      )}
      <StyledBackgroundVideo
        url={video}
        muted={!isActive}
        controls={isActive}
        loop={!isActive}
        onEnded={() => setActive(false)}
      />
    </StyledVideoBlock>
  )
}

export const Video = ({ heading, subtitle, image, videoUrl }) => {
  const [isActive, setActive] = useToggle(false)
  return (
    <StyledVideo>
      <MotionContainer>
        <VideoBlockPlayer
          key={`video-${isActive}`}
          video={isActive ? videoUrl : ""}
          isActive={isActive}
          setActive={setActive}
          heading={heading}
          subtitle={subtitle}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            transition: {
              type: "spring",
              damping: 600,
            },
          }}
        />
        {image && (
          <Img
            isActive={isActive}
            setActive={setActive}
            fluid={isActive ? "" : image.asset.fluid}
          />
        )}
      </MotionContainer>
    </StyledVideo>
  )
}
