import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import is, { isNot } from "styled-is"
import Headroom from "react-headroom"
import { motion } from "framer-motion"

// Hooks,Querys & State
import { useWindowSize } from "../../hooks"
import { useAppContext } from "../../state"
import { useSiteSettings } from "../../GraphQl/queryHooks"

// Common Components
import { NavLink } from "../common"
import { MenuToggle } from "./menuToggle"
import { MobileNav } from "./mobileNav"

// SVG
import Logo from "../../svg/logo.svg"

// Animation Variants

const variants = {
  open: {
    background: '#031E44',
  },
  closed: {
    background:'#EFF5FF',    
  },
}




// Styles
const StyledSiteHeader = styled(props => <Headroom {...props} />)`
  z-index: 99999 !important;
  position: absolute;
    top: 0;
    left: 0;
    width:100%;
  background-color: ${props => props.theme.colors.primary.three};
  header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.4rem;    
    width: 100%;
    z-index: 9999;
    button {
      padding: 0;
      margin: 0;
    }
  }
  header button,
  header button svg {
    overflow:visible;
  }
  .headroom--pinned header {
    background: white;
    svg .logoPrimary {
        fill: #031E44 !important;
    }
  }  
  ${is("navOpen")`
    header,
    .headroom--pinned header {      
      svg .logoPrimary {
        fill: white !important;
      }
    }
  `}
  @media ${props => props.theme.breakpoints.medium} {
    background-color: unset;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    header {
      align-items: center;
      padding:2rem;
      svg .logoPrimary {
        fill: ${props =>
          props.reverseHeader ? `#ffffff` : `#031E44`} !important;
      }
      
      nav a {
        font-size:18px;
        font-weight: 600;
        margin-left: 37px;
      }
    }
    header {
      background-color:transparent !important;
    }
    ${isNot("navOpen")`
    .headroom--pinned {
      header{
        background-color: rgba(255,255,255,1) !important;
      }
    }
  `}
  }
  @media ${props => props.theme.breakpoints.large} {
    
  }
`

const StyledHomeLink = styled(Link)`
  width: 130px;
  svg {
    width: 100%;
  }
  @media ${props => props.theme.breakpoints.medium} {
    width: 215px;
    svg .logoPrimary {
      fill: white !important;
    }
  }
`

export const Header = () => {
  const { mainNav } = useSiteSettings()
  const { navOpen, toggleMenu, reverseHeader, thirdHeader } = useAppContext()
  const windowSize = useWindowSize()
  return (
    <>
      <StyledSiteHeader reverseHeader={reverseHeader} navOpen={navOpen}>
        
          <motion.header 
          variants={variants} 
          initial="closed"
          animate={navOpen ? 'open' : 'closed'}>          
          <StyledHomeLink to="/">
            <Logo thirdHeader={thirdHeader}/>
          </StyledHomeLink>
          {windowSize.width >= 1024 && (
            <nav>
              {mainNav.map(item => (
                <NavLink {...item} />
              ))}
            </nav>
          )}
          {windowSize.width < 1024 && (
            <MenuToggle isOpen={navOpen} onClick={toggleMenu} />
          )}
          </motion.header>
      </StyledSiteHeader>
      <MobileNav items={mainNav} />
    </>
  )
}
