import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"

// Import components
import { StandardContainer, StyledHeading } from "../common"

// Styles
const StyledBannerCladding = styled(StandardContainer)`
  /* max-height: 100vh; */
  .gatsby-image-wrapper {
    > div {
      display: none;
    }
    padding-bottom: 80%;
  }
  @media ${props => props.theme.breakpoints.medium} {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    .gatsby-image-wrapper {
      width: 60%;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    .gatsby-image-wrapper {
      padding-bottom: 100vh;
    }
  }
`

const TextWrapper = styled(StandardContainer)`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1.4rem 1.4rem;
  background-color: ${props => props.theme.colors.primary.one};
  color: white;
  h3 {
    margin-bottom: 4rem;
  }
  button {
    display: none;
    color: white;
    font-weight: 600;
    margin-top: 1rem;
  }
  @media ${props => props.theme.breakpoints.medium} {
    align-content: flex-end;
    width: 40%;
    padding: 3rem;
    h3 {
      margin-bottom: 0.5rem;
    }
    button {
      display: block;
    }
  }
`

const Button = styled(AnchorLink)``

export const BannerCladding = ({ heading, image, description, linkText }) => {
  return (
    <StyledBannerCladding>
      {image && <Img fluid={image.asset.fluid} />}
      <TextWrapper padH>
        {heading && (
          <StyledHeading as="h3" size="h3" reversed>
            {heading}
          </StyledHeading>
        )}
        <p>{description}</p>
        <Button href={`#tabbedContent`}>{linkText}</Button>
      </TextWrapper>
    </StyledBannerCladding>
  )
}
