import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

// Import components
import { StandardContainer, StyledHeading, SanityPortableText } from "../common"

// Specs
const SpecSingleLine = ({ heading, content, ...props }) => {
  return (
    <motion.section {...props}>
      <StyledHeading as="h3" size="h6" noMargin>
        {heading}
      </StyledHeading>
      {content && <p>{content}</p>}
    </motion.section>
  )
}

const SpecMultiLine = ({ heading, _rawContent, files, ...props }) => {
  return (
    <motion.section {...props}>
      <StyledHeading as="h3" size="h6" noMargin>
        {heading}
      </StyledHeading>
      <div>
        {_rawContent && <SanityPortableText text={_rawContent} />}
        {files && (
          <a target="_blank" rel="noreferrer" href={files[0].file.asset.url}>
            {files[0].heading}
          </a>
        )}
      </div>
    </motion.section>
  )
}

// Styles
const StyledSpecTable = styled(StandardContainer)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-bottom: solid 1px #031e44;
    padding: 1rem 0;
    font-size:14px;
  }
  h3 {
    font-weight: 600;
    width: 100%;
  }
  a {
    display:block;
    font-weight:600;
  }
  @media ${props => props.theme.breakpoints.medium} {
    flex-wrap: wrap;
    section {
      display:flex;
      flex-wrap: nowrap;
      > p,
      > div {
        display:inline-block;
        width:60%;
      }
    }
    
    h3 {
      display:inline-block;
      width: 40%;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
  }
`

export const SpecTable = ({ heading, content }) => {
  return (
    <StyledSpecTable>
      {/* {heading && (
        <StyledHeading as="h3" size="h3">
          {heading}
        </StyledHeading>
      )} */}
      {content.map(item => {
        if (item._type === "specMultiLine") {
          return <SpecMultiLine {...item} />
        } else {
          return <SpecSingleLine {...item} />
        }
      })}
    </StyledSpecTable>
  )
}
