import React, { useState, useCallback, useEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useEmblaCarousel } from "embla-carousel/react"
// import { setupWheelGestures } from "embla-carousel-wheel-gestures"

// Components
import { StandardContainer } from "../common"

// Styles
const StyledCardCarousel = styled(StandardContainer)`
  position: relative;
  > nav {
    text-align: center;
    position: absolute;
    right: 1.4rem;
    bottom: 1.4rem;
    z-index: 1;
    button {
      width: 8px;
      height: 8px;
      padding: 0;
      border-radius: 15px;
      box-sizing: border-box;
      background: white;
      margin: 0 6px;
      cursor: pointer;
      transition: opacity 0.2s;
      &.is-selected {
        background: ${props => props.theme.colors.primary.two};
      }
    }
    @media ${props => props.theme.breakpoints.medium} {
      right: 2rem;
      bottom: 2rem;
      button {
        margin: 0 0 0 12px;
        width: 12px;
        height: 12px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    > nav {
      padding-left: var(--pH);
      padding-right: var(--pH);
    }
  }
`

const StyledCardCarouselCanvas = styled.div`
  display: flex;
`
const SlideWrapper = styled.div`
  width: 100%;
  flex: 0 0 auto;
  box-sizing: content-box;
  .gatsby-image-wrapper {
    padding-bottom: 62.5%;
    > div {
      display: none;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    .gatsby-image-wrapper {
      padding-bottom: 100vh;
    }
  }
`

const DotButton = ({ selected, onClick }) => (
  <button
    className={`embla__dot ${selected ? "is-selected" : ""}`}
    type="button"
    onClick={onClick}
  />
);


export const CarouselSingle = ({ images }) => {
  const [EmblaCarouselReact, embla] = useEmblaCarousel({
    loop: false,
    dragFree: false,
    align: "start",
    containScroll: true,
  })

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [
    embla
  ]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <>
      <StyledCardCarousel>
        <EmblaCarouselReact>
          <StyledCardCarouselCanvas>
            {images.map((image, index) => (
              <SlideWrapper className="embla__slide" key={index}>
                <Img fluid={image.asset.fluid} />
              </SlideWrapper>
            ))}
          </StyledCardCarouselCanvas>
        </EmblaCarouselReact>
        <nav>
          <div className="embla__dots">
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                selected={index === selectedIndex}
                onClick={() => scrollTo(index)}
              />
            ))}
          </div>
        </nav>
      </StyledCardCarousel>
    </>
  )
}

