import React, { useEffect } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { Link } from "gatsby"
import Div100vh from 'react-div-100vh'

// Hooks State
import { useAppContext } from "../../state"
// Import components
import { StandardContainer, StyledHeading } from "../common"
import { ImageCarousel } from "./"

// Styles
const StyledHero = styled(StandardContainer)`
  height:100%;  
  nav {
    left: unset;
    transform: unset;
    right: 1.4rem;    
  }
  .image-carousel {
    height:65%;
    position: -webkit-relative; /* Safari */
    position: relative;
    top: unset;
    z-index:unset;
    div {
      height:100%;
    }
  }
  .image-carousel .gatsby-image-wrapper {
    padding-bottom: 0 !important;
    height:100%;
  }
  @media ${props => props.theme.breakpoints.medium} {        
    nav {
      right: 3rem;
      bottom: 3rem;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    background: ${props => props.bgColor || "#fff"};
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    flex-direction: row-reverse;
    .image-carousel {
      width: 60%;
    }
    .gatsby-image-wrapper {
      width: 100%;
      height: 100% !important;
    }
  }
`

const StyledButton = styled(Link)`
  color: white;
  font-weight: 600;
  margin-top: 1rem;
  @media ${props => props.theme.breakpoints.medium} {
  }
`

const TextWrapper = styled(StandardContainer)`
background-color: ${props => props.theme.colors.primary.two};
  padding: 1rem 1.4rem 1.4rem;
  height: 35%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  position: relative;
  z-index: 1;
  overflow: hidden;
  h3,
  a {
    z-index: 1;
  }
  .gatsby-image-wrapper {
    padding: 0;
    z-index: 0;
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  @media ${props => props.theme.breakpoints.medium} {
    padding: 3rem 2rem;
    h3 {
      margin-bottom: 0;
    }
    
  }
  @media ${props => props.theme.breakpoints.large} {
    width: 40%;
    height: 50%;
    * {
      width: 100%;
    }
    .gatsby-image-wrapper {
      transition: all 0.2s ease;
    }
    &:hover {
      .gatsby-image-wrapper {
        opacity:0;
      }
    }
  }
`

export const HeroCarousel = ({
  heading,
  bgColor,
  linkTarget,
  linkText,
  images,
  secondaryImage,
}) => {
  const { setReverseHeader } = useAppContext()
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  })

  useEffect(() => {
    setReverseHeader(inView)
    return () => {
      setReverseHeader(false)
    }
  }, [inView, setReverseHeader])

  return (
    <Div100vh style={{ height: '100rvh' }}>
      <StyledHero bgColor={bgColor} ref={ref}>
        <ImageCarousel images={images} />
        <TextWrapper>
          {heading && (
            <StyledHeading as="h3" size="h3" reversed>
              {heading}
            </StyledHeading>
          )}
          {linkTarget && (
            <StyledButton to={linkTarget.slug.current}>{linkText}</StyledButton>
          )}
          {secondaryImage && <Img fluid={secondaryImage.asset.fluid} />}
        </TextWrapper>
      </StyledHero>
    </Div100vh>
  )
}
