import React from "react"
import styled from "styled-components"

// Import components
import { StandardContainer, StyledHeading } from "../common"

function lightOrDark(color) {

  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {

    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  }
  else {

    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {

    return 'light';
  }
  else {

    return 'dark';
  }
}

const ColorCard = ({ colorPicker, colorName, colorCode }) => {
  const ColorDarkness = lightOrDark(colorPicker.hex);
  return (
    <>
      <div className={ColorDarkness  === 'light' ? 'colorCard' : 'colorCard dark'} style={{ background: colorPicker.hex }} darkness={ColorDarkness}>
        <span>
          <StyledHeading as="h4" size="h6" noMargin>
            {colorName}
          </StyledHeading>
          <StyledHeading as="h5" size="h6" noMargin>
            {colorCode}
          </StyledHeading>
        </span>
      </div>
    </>
  )
}

// Styles

// Styles
const StyledColorGrid = styled(StandardContainer)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  column-gap: 20px;
  row-gap: 20px;
  justify-items: stretch;
  .colorCard {    
    width: 100%;
    padding-bottom: 125%;
    position: relative;
    border: solid 1px #f1f1f1;
    box-sizing: border-box;
    &.dark {
      color:white;
    }
  span {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
  h4 {
    font-weight: 600;
  }
  }
  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
  }
  @media ${props => props.theme.breakpoints.xl} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto auto;
  }
`

export const ColorGrid = ({ heading, content }) => {
  return (
    <StyledColorGrid>
      {content.length ? (
        <>
          {content.map((item, index) => (
            <ColorCard {...item}>
              
            </ColorCard>
          ))}
        </>
      ) : (
        <StyledHeading size="h2" centerText measureWide>
          Add some cards
        </StyledHeading>
      )}
    </StyledColorGrid>
  )
}
